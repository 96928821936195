import React, { useState } from "react"
import { graphql } from "gatsby"

import urlBuilder from "@sanity/image-url"
import { mySanityClient } from "../sanityClient"

const ImageOverlay = ({
  closeOverlay,
  toggleOverlay,
  imageUrl = null,
  albumsArray,
  nextImage,
}) => {
  return (
    <div className="fixed top-0 bg-gray-600 bg-opacity-25 bg-opacity-75 h-full w-full flex flex-col items-center text-white">
      <p
        className="cursor-pointer absolute top-0 right-0 font-bold mt-5 mr-5"
        onClick={() => closeOverlay()}
      >
        X CLOSE
      </p>
      <p>PREV</p>

      <p onClick={() => nextImage()}>NEXT</p>
      <h1>This is an example image overlay</h1>
      <div>Photos go here</div>
      <img src={imageUrl} />
    </div>
  )
}

const ExampleAlbum = ({ data }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayImageUrl, setShowOverlayImageUrl] = useState(
    "https://cdn.sanity.io/images/ryjfji36/production/80114327b96572f37d573e0f3677067e08b1252b-567x567.png"
  )
  const [albumIndexNum, setAlbumIndexNum] = useState(0)
  const [urlIndexNum, setUrlIndexNum] = useState(0)
  const [albumsArray, setAlbumsArray] = useState([])

  const toggleOverlay = (url, albumIndex, urlIndex, albumArray) => {
    setAlbumIndexNum(1)
    setUrlIndexNum(2)
    const i = urlIndexNum

    const urlToUse = albumArray[albumIndexNum].imageUrlArray[2]

    setShowOverlayImageUrl(url)
    // setIndexNum(urlIndex)
    setAlbumsArray(albumArray)

    return setShowOverlay(!showOverlay)
  }

  const closeOverlay = () => setShowOverlay(false)

  const nextImage = urlIndexNum => console.log("Go to next image")

  // All albums
  const albumData = data.albums.edges
  // Create an array of albums, with an object that has the data I need, i.e.:
  // Album Title

  // Create custom Array of albums, with urls for each image in the album
  const customAlbumArray = albumData.map(album => {
    // Array of images
    const imageArray = album.node._rawImageSet
    // for each album, get the title
    const title = album.node.title
    // helper functions to create a url from a sanity asset (or asset ID)
    const builder = urlBuilder(mySanityClient)
    const urlFor = source => builder.image(source)
    // create an array of urls for the album
    const imageUrlArray = imageArray.map(imageData =>
      urlFor(imageData.asset.id).url()
    )
    return { title, imageUrlArray }
  })

  return (
    <>
      <div>
        {customAlbumArray.length > 0 && (
          <div>
            {customAlbumArray.map((album, index) => {
              const albumIndex = index

              return (
                <div>
                  <h1>{album.title}</h1>
                  {album.imageUrlArray.map((url, index) => {
                    const urlIndex = index
                    return (
                      <img
                        src={url}
                        onClick={() =>
                          toggleOverlay(
                            url,
                            albumIndex,
                            urlIndex,
                            customAlbumArray
                          )
                        }
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
      {/* only show if there are items in the imagesArray */}
      {showOverlay && (
        <ImageOverlay
          closeOverlay={closeOverlay}
          toggleOverlay={toggleOverlay}
          imageUrl={overlayImageUrl}
          albumsArray={albumsArray}
          nextImage={nextImage}
        />
      )}
    </>
  )
}

export const query = graphql`
  query AlbumsQuery {
    albums: allSanityAlbum {
      edges {
        node {
          id
          title
          _rawImageSet(resolveReferences: { maxDepth: 10 })

          imageSet {
            asset {
              id
              assetId
            }
          }
        }
      }
    }
  }
`

export default ExampleAlbum
